<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px" :rules="rules" ref="formInfo">
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title"> {{ !isEdit ? "新增" : "编辑" }}开卡单记录</span>
          <div class="button-back">
            <el-button @click="() => {
              $router.go(-1);
            }
              " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <el-form-item label="开卡类型">
          <el-radio-group :disabled="isEdit" v-model="activeName" @input="handleTabClick">
            <el-radio label="all">整单开卡</el-radio>
            <el-radio label="part">部分开卡</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 整单 -->
        <div v-if="activeName == 'all'" key="all">
          <div class="card-body">
            <el-form-item label="关联销售关卡单号" prop="all.close_trade_sn" :label-width="labelWidth">
              <el-input size="small" v-model.trim="formInfo.all.close_trade_sn" placeholder="请输入关联销售关卡单号" clearable
                style="width: 200px" @blur="searchTradeSn" :disabled="isEdit"></el-input>
            </el-form-item>
            <el-form-item label="应用类型" prop="all.app_type_shop_type_flag" :label-width="labelWidth">
              <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.all.app_type_shop_type_flag" clearable
                style="width: 200px" :disabled="true">
                <el-option v-for="item in cardTypes" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>

            <el-form-item label="客户名称" prop="all.client_name" :label-width="labelWidth">
              <el-autocomplete size="small" v-model.trim="formInfo.all.client_name"
                :fetch-suggestions="queryClientSearchAsync" placeholder="请选择客户名称" style="width: 200px" :maxlength="64"
                :disabled="true"></el-autocomplete>
            </el-form-item>

            <el-form-item label="关联卡号数据" prop="all.ids" :label-width="labelWidth">
              <!-- <el-button
                type="primary"
                size="small"
                style="margin-bottom: 10px"
                @click="saleOrderDialog.display()"
              >
                选择销售单
              </el-button> -->
              <order-table type="open" :orderList="orderList" :cache="true" :disabled="true" />
            </el-form-item>

            <el-form-item label="备注信息" :label-width="labelWidth">
              <el-input size="small" type="textarea" :placeholder="'请输入200字以内的备注信息'" :maxlength="200" rows="5"
                v-model="formInfo.all.remark" />
            </el-form-item>
          </div>

          <x-dialog :proxy="saleOrderDialog">
            <select-sale-order-list type="open" @select="handleSelectItems" />
          </x-dialog>
        </div>
        <!-- 整单 -->

        <!-- 部分 -->
        <div v-if="activeName == 'part'" key="part">
          <el-form-item label="关联销售关卡单号" prop="part.close_trade_sn" :label-width="labelWidth">
            <el-input size="small" v-model.trim="formInfo.part.close_trade_sn" placeholder="请输入关联销售关卡单号" clearable
              style="width: 200px" @blur="searchTradeSnPart" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="应用类型" prop="part.app_type_shop_type_flag" :label-width="labelWidth">
            <el-select size="small" placeholder="请选择应用类型" v-model="formInfo.part.app_type_shop_type_flag" clearable
              style="width: 200px" :disabled="true">
              <el-option v-for="item in cardTypes" :key="item.value" :value="item.value" :label="item.label" />
            </el-select>
          </el-form-item>

          <el-form-item label="客户名称" prop="part.client_name" :label-width="labelWidth">
            <el-autocomplete size="small" v-model="formInfo.part.client_name" :fetch-suggestions="queryClientSearchAsync"
              placeholder="请选择客户名称" style="width: 200px" :disabled="true"></el-autocomplete>
          </el-form-item>

          <div class="card-body-1">
            <el-form-item label="录入卡号" :label-width="labelWidth">
              <div class="card-exp-area">
                <!-- 卡号上传 -->
                <card-upload :shopId="'-1'" :appType="formInfo.part.app_type_shop_type_flag"
                  @change="handleChangeCardList" @submit="exportCard" />
                <!-- 卡号上传 -->
              </div>
            </el-form-item>

            <!-- 卡券信息 -->
            <card-list-x4 ref="card_list" @change="handleChangeCardList" :cardData="formInfo.part.cardList"
              :filterColumn="filterColumn" :disabled="disabledColumn" :appType="formInfo.part.app_type_shop_type_flag"
              :tradeSnData="tradeSnList" :close_sn="formInfo.part.close_trade_sn" @range="changeCardRange" />
            <!-- 卡券信息 -->

            <el-form-item label="备注信息" :label-width="labelWidth">
              <el-input size="small" type="textarea" :maxlength="200" placeholder="请输入200字以内的备注信息" rows="5"
                v-model.trim="formInfo.part.remark" />
            </el-form-item>
          </div>
        </div>
        <!-- 部分 -->

        <el-form-item align="center" style="width: 80%">
          <el-button type="primary" :disabled="saveDisabled" @click="isPermission">保存</el-button>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import mixins from "./saleOrderRefundMixins";
import * as API_Order from "@/api/order";
import { asyncRouterMap } from "@/router";
import cardListX4 from "@/views/tools/sale-card-open/components/cardListX4";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";
import selectSaleOrderList from "@/views/tools/sale-card-open/components/selectSaleOrderList";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import orderTable from "@/views/tools/sale-card-open/components/orderTable";

export default {
  name: "editSaleOpenRecord",
  mixins: [mixins],
  components: {
    cardListX4,
    cardUpload,
    selectSaleOrderList,
    orderTable,
  },
  data () {
    return {
      labelWidth: "140px",
      saleOrderDialog: $xDialog.create({
        title: "销售订单选择器",
        width: "900px",
        disableConfirm: true,
        disableCancel: true,
      }),
      activeName: this.$route.query.type || "all",
      orderList: [],
      orderData: [],
      filterColumn: ["统一折扣率设置"],
      disabledColumn: [
        "面值/价位",
        "折扣率(%)",
        "优惠金额",
        "优惠后金额",
        "统一折扣率设置",
      ],
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      sn: this.$route.query.sn,
      formInfo: {
        all: {
          close_trade_sn: "", // KG20230412000004
          app_type_shop_type_flag: "",
          client_name: "",
          ids: "",
          remark: "",
        },
        part: {
          close_trade_sn: "", // KG20230428000001 // KG20230411000001
          app_type_shop_type_flag: "",
          client_name: "",
          cardList: [],
          remark: "",
        },
      },
      rules: {
        all: {
          close_trade_sn: [
            {
              required: true,
              message: "请输入关联销售关卡单号",
              trigger: ["blur", "change"],
            },
          ],
          app_type_shop_type_flag: [
            {
              required: true,
              message: "请选择应用类型",
              trigger: ["blur", "change"],
            },
          ],
          client_name: [
            {
              required: true,
              message: "请输入客户名称",
              trigger: ["blur", "change"],
            },
          ],
          ids: [
            {
              required: true,
              message: "请选择销售单",
              trigger: ["blur", "change"],
            },
          ],
        },
        part: {
          close_trade_sn: [
            {
              required: true,
              message: "请输入关联销售关卡单号",
              trigger: ["blur", "change"],
            },
          ],
          app_type_shop_type_flag: [
            {
              required: true,
              message: "请选择应用类型",
              trigger: ["blur", "change"],
            },
          ],
          client_name: [
            {
              required: true,
              message: "请输入客户名称",
              trigger: ["blur", "change"],
            },
          ],
        },
      },
      saveDisabled: false,
      shopCardList: [],
      phoneError: "",
      close_open_sn: null,
      tradeSnList: [],
    };
  },
  activated () {
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    this.checkPermission();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
    this.getClientList();
    this.getEidtInfo();
  },
  methods: {
    getEidtInfo () {
      if (!this.isEdit) return;
      let oper_type = this.activeName == "all" ? 2 : 1;
      API_saleCard.closeOpenCardDetails3({
        sn: this.sn,
      }).then((res) => {
        // this.formInfo = res;
        const { remark, app_type_shop_type_flag, client_name, sn, shop_close_sell_dos } = res;
        this.close_open_sn = sn;
        if (oper_type === 2) {
          this.formInfo.all = {
            close_trade_sn: sn,
            remark,
            app_type_shop_type_flag,
            client_name,
          };
          this.$nextTick(() => {
            this.orderList = shop_close_sell_dos.map((item) => {
              item.shop_trade_sell_list = item.shop_close_open_sell_dos;
              return item;
            });
          });
        } else {
          const { remark, app_type_shop_type_flag, client_name, close_open_sell_card_dos } =
            res;
          close_open_sell_card_dos.forEach(item => {
            let cmp = item.card_code_start.match(/^[a-zA-Z]+/);
            let code_prefix = cmp ? cmp[0] : "";
            item.code_prefix = code_prefix;
            item.card_code_start_str = code_prefix ? item.card_code_start.split(code_prefix)[1] : (item.card_code_start + "")
            item.card_code_end_str = code_prefix ? item.card_code_end.split(code_prefix)[1] : (item.card_code_end + "")
          })
          this.formInfo.part = {
            close_trade_sn:
              close_open_sell_card_dos[0] &&
              close_open_sell_card_dos[0].close_sn,
            app_type_shop_type_flag,
            client_name,
            remark,
            cardList: [...close_open_sell_card_dos],
          };
        }
      });
    },
    createCardInfo ({ type, item, cards }) {
      let close_trade_vos = [];
      for (const key in cards) {
        let info = {
          trade_sn: key,
          close_card_vos: [],
        };
        let shops = cards[key];
        let _obj = this.groupArrayByKey(shops, "shop_id");
        for (const key in _obj) {
          let sp = {
            shop_id: !key ? "" : +key,
            shop_name: key ? (shops.find(s => s.shop_id == key).shop_name) : "",
            close_card_sell_vos: [],
          };
          let cds = _obj[key];
          let _obj2 = this.groupArrayByKey(cds, "card_id");
          for (const key in _obj2) {
            let item_list = _obj2[key];
            let num_and_section = 1;
            const { card_name, card_value } = item_list.find(c => c.card_id == key)
            let section_item = {
              card_id: !key ? "" : +key,
              card_name,
              card_value,
              close_card_sell_section_vos: [],
            };
            if (type == "num") {
              let total = item_list[0].merge_card_num;
              let pnum = item_list
                .map((d) => d.card_num)
                .reduce((a, b) => +a + +b, 0);
              section_item.change_num = total - pnum;
              num_and_section = 0;
            }
            item_list.forEach((_item) => {
              let cmp = _item.card_code_start.match(/^[a-zA-Z]+/);
              let code_prefix = cmp ? cmp[0] : "";
              let card_code_num_start = cmp
                ? _item.card_code_start.split(code_prefix)[1]
                : _item.card_code_start;
              let card_code_num_start_str = code_prefix ? _item.card_code_start.split(code_prefix)[1] : (_item.card_code_start + "")
              let card_code_num_end = cmp
                ? _item.card_code_end.split(code_prefix)[1]
                : _item.card_code_end;
              let card_code_num_end_str = code_prefix ? _item.card_code_end.split(code_prefix)[1] : (_item.card_code_end + "")
              let is_change = type == "num" ? 0 : +(_item === item);
              section_item.close_card_sell_section_vos.push({
                ..._item,
                code_prefix,
                card_code_num_start,
                card_code_num_end,
                card_code_num_start_str,
                card_code_num_end_str,
                num_and_section,
                is_change,
              });
            });
            sp.close_card_sell_vos.push(section_item);
          }
          info.close_card_vos.push(sp);
        }
        close_trade_vos.push(info);
      }
      return close_trade_vos.filter((item) => item.card_num != 0);
    },
    changeCardRange (opt) {
      // console.log(cards)
      const { app_type_shop_type_flag, close_trade_sn } = this.formInfo.part;
      const close_open_sn = this.close_open_sn;
      let close_trade_vos = this.createCardInfo(opt);

      let params = {
        app_type_shop_type_flag,
        close_trade_vos,
        close_sn: close_trade_sn,
        close_open_sn,
      };
      API_saleCard.sectionCloseOpenCardCheckout(params)
        .then((res) => {
          if ((res.cards_key_error || []).length > 0) {
            this.checkCardRange([...res.cards_key_error], 6)
              .then((type) => {
                if (type == 1) {
                  this.formInfo.part.cardList = this.createCardList(
                    res.close_trade_vos
                  );
                }
                else {
                  this.$refs.card_list.backtrack();
                }
              });
            return;
          }
          this.formInfo.part.cardList = this.createCardList(
            res.close_trade_vos
          );
        })
        .catch(() => {
          this.$refs.card_list.backtrack(opt);
        });
    },
    createCardList (close_trade_vos) {
      let cardList = close_trade_vos
        .map((sn) => {
          const { trade_sn, close_card_vos } = sn;
          let sns = close_card_vos.map((shop) => {
            const { shop_id, shop_name } = shop;
            let cards = shop.close_card_sell_vos.map((card) => {
              const { card_id, card_name, card_value } = card;
              let items = card.close_card_sell_section_vos.map((item) => {
                const { code_prefix, card_code_num_start, card_code_num_end, card_code_num_start_str, card_code_num_end_str } =
                  item;
                item.card_id = card_id || "";
                item.card_name = card_name || "";
                item.card_value = card_value || "";
                item.shop_id = shop_id || "";
                item.shop_name = shop_name || "";
                item.card_code_start = (code_prefix || "") + ((card_code_num_start_str || "") + "");
                item.card_code_end = (code_prefix || "") + ((card_code_num_end_str || "") + "");
                item.trade_sn = trade_sn;
                return item;
              });
              return [...items];
            });
            return [...cards];
          });
          return [...sns];
        })
        .flat(4);
      return cardList;
    },
    exportCard (params) {
      const { close_open_sn } = this;
      const close_sn = this.formInfo.part.close_trade_sn;

      const { app_type_shop_type_flag, card_code_list } = params;
      let cards = this.$refs.card_list.getCardData();
      let close_trade_vos;

      if (Object.keys(cards).length === 1 && Object.keys(cards)[0] == "") {
        close_trade_vos = []
      }
      else {
        close_trade_vos = this.createCardInfo({ cards });
      }

      if (!this.checkCardSaveX4(cards)) {
        return this.$message.error("请完善卡券信息");
      }

      API_saleCard.sectionOpenCardInPutCheckout({
        app_type_shop_type_flag,
        close_sn,
        close_open_sn,
        close_trade_vos,
        close_in_put_card_vo: {
          app_type_shop_type_flag,
          close_open_sn,
          card_code: card_code_list,
        },
      }).then((res) => {
        const { close_error_card_vo, close_trade_vos } = res;
        if (close_error_card_vo) {
          const { error_type, error_card_map } = close_error_card_vo;
          this.checkCardExportRules2(error_type, error_card_map, app_type_shop_type_flag);
          return;
        }
        this.formInfo.part.cardList = this.createCardList(close_trade_vos);
      });
    },
    searchTradeSnPart () {
      if (this.isEdit) return;
      let close_sn = this.formInfo.part.close_trade_sn;
      if (!close_sn) return this.$message.error("关联销售关卡单号");
      API_saleCard.queryCloseOpenInFo({
        close_sn,
      })
        .then((res) => {
          const { app_type_shop_type_flag, client_name, remark, close_sell_trade_sn_list } =
            res;
          this.formInfo.part.app_type_shop_type_flag = app_type_shop_type_flag;
          this.formInfo.part.client_name = client_name;
          this.formInfo.part.remark = this.formInfo.part.remark || "";
          this.tradeSnList = close_sell_trade_sn_list || [];
        })
        .catch(() => {
          this.formInfo.part.app_type_shop_type_flag = "";
          this.formInfo.part.client_name = "";
          this.formInfo.part.cardList = [];
          this.formInfo.part.remark = this.formInfo.part.remark || "";
          this.tradeSnList = [];
        });
    },
    searchTradeSn () {
      if (this.isEdit) return;
      let sn = this.formInfo.all.close_trade_sn;
      if (!sn) return this.$message.error("关联销售单号");
      API_saleCard.openCardDetails({
        sn,
      })
        .then((res) => {
          const { remark, app_type_shop_type_flag, client_name, sn, shop_close_sell_dos } =
            res;
          this.formInfo.all = {
            ...this.formInfo.all,
            remark: this.formInfo.all.remark || "",
            app_type_shop_type_flag,

            client_name,
            close_trade_sn: sn,
          };
          this.$nextTick(() => {
            this.orderList = shop_close_sell_dos.map((item) => {
              item.shop_trade_sell_list = item.shop_close_sell_dos;
              return item;
            });
            // console.log(this.formInfo.all);
          });
        })
        .catch(() => {
          this.formInfo.all.app_type_shop_type_flag = "";
          this.formInfo.all.client_name = "";
          this.formInfo.all.ids = "";
          this.formInfo.all.remark = this.formInfo.all.remark || "";
          this.orderList = [];
        });
    },
    createStateFilter (queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    // 切换
    handleTabClick () {
      this.formInfo = {
        all: {
          close_trade_sn: "",
          app_type_shop_type_flag: "",
          client_name: "",
          textarea: "",
          ids: "",
        },
        part: {
          close_trade_sn: "",
          app_type_shop_type_flag: "",
          client_name: "",
          textarea: "",
          cardList: [],
        },
      };
      this.orderList = [];
      this.getEidtInfo();
    },
    // 获取商城的卡券列表
    getShopCardList (val) {
      API_saleCard.getShopCardList(val, this.formInfo.all.app_type_shop_type_flag == '' ? this.formInfo.part.app_type_shop_type_flag : this.formInfo.all.app_type_shop_type_flag).then((res) => {
        this.shopCardList = res.map((item) => {
          return {
            value: item.id,
            label: item.card_name,
          };
        });
      });
    },
    // 商城下拉列表change事件
    handleShopChange (val) { },
    handleShopFocus (val) {
      if (!this.formInfo[this.activeName].app_type_shop_type_flag) {
        return this.$message.error("请先选择应用类型");
      }
      if (this.activeName == "part") {
        API_saleCard.queryCloseCardsShop({}).then((res) => { });
      } else this.getShopList();
    },
    // 判断是否有销售开卡权限
    isPermission () {
      API_Order.getShopExtAuth().then((res) => {
        if (res.open === "OPEN") {
          this.save();
        } else {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000);
        }
      });
    },
    // 保存
    save () {
      const _params = {
        // card_info_list: this.formInfo.cardList,
        ...this.formInfo[this.activeName],
      };

      console.log(this.$refs.formInfo);
      // console.log(_params);
      // delete _params["cardList"];
      // 校验表单
      this.$refs.formInfo.validate((validate) => {
        if (!validate) return this.$message.error("请检查必填项");
        // 整单
        const { close_open_sn } = this;
        if (this.activeName == "all") {
          API_saleCard.openSave({
            close_open_sn,
            sn: this.formInfo.all.close_trade_sn,
            remark: this.formInfo.all.remark,
          }).then((res) => {
            this.$message.success("保存成功");
            this.$router.go(-1);
          });
        }
        // 部分
        else {
          const close_sn = this.formInfo.part.close_trade_sn;
          const { remark, app_type_shop_type_flag } = this.formInfo.part;
          let cards = this.$refs.card_list.getCardData();
          if (cards[""] && cards[""].length) {
            return this.$message.error("请填写完整卡券信息");
          }
          if (!this.checkCardSaveX4(cards, true)) {
            return this.$message.error("请完善卡券信息");
          }
          let close_trade_vos = this.createCardInfo({ cards });
          API_saleCard.sectionCloseCardOpen({
            close_open_sn,
            close_sn,
            remark,
            app_type_shop_type_flag,
            close_trade_vos,
          }).then((res) => {
            this.$message.success("保存成功");
            this.$router.go(-1);
          });
        }
      });
    },
    handleChangeCardList () { },
    handleSelectItems (items) {
      if (this.orderList.length === 0) this.orderList = items;
      else {
        items.forEach((item) => {
          if (!this.orderList.some((o) => o.id == item.id)) {
            this.orderList.push(item);
          }
        });
      }
      this.saleOrderDialog.visible = false;
    },
  },
  watch: {
    orderList: {
      handler (v) {
        this.formInfo.all.ids = v.map((o) => o.id).join(",");
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";

.order-table {

  /deep/ .el-table,
  /deep/ .el-table__body-wrapper.is-scrolling-none {
    height: auto !important;
  }
}
</style>
